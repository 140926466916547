<template>
  <div class="projects">
    <h1 class="sr-only">{{ $t('projects.title') }}</h1>
    <b-form v-if="viewEntry">
      <ViewButtons @goBack="goBack"/>
      <b-card class="mx-2 mx-md-5" :header="this.$i18n.t('comp.projects.show.label')">
        <b-form-group :label="$t('comp.projects.projectId.label')">
          <b-form-input v-model="form.projectId" readonly/>
        </b-form-group>
        <b-form-group :label="$t('comp.projects.title.label')">
          <b-form-input v-model="form.title" readonly/>
        </b-form-group>
        <b-form-group :label="$t('comp.projects.unixGroupId.label')">
          <b-form-input v-model="form.unixGroupId" readonly/>
        </b-form-group>
        <b-form-group :label="$t('comp.projects.unixGroupName.label')">
          <b-form-input v-model="form.unixGroupName" readonly/>
        </b-form-group>
        <b-form-group :label="$t('comp.projects.projecttype.label')">
          <b-form-input v-model="form.projectType.label" readonly/>
        </b-form-group>
        <b-form-group :label="$t('comp.projects.domain.label')">
          <b-form-input v-model="this.domainLabel" readonly/>
        </b-form-group>
        <b-form-checkbox v-model="form.aiRelated" name="aiRelated" class="my-3" switch disabled>
          {{ $t('comp.projects.airelated.long.label') }}
        </b-form-checkbox>
        <b-form-group :label="$t('comp.projects.province.label')">
          <b-form-input v-model="this.provinceLabel" readonly/>
        </b-form-group>
        <b-form-group :label="$t('comp.projects.organization.label')">
          <b-form-input v-model="form.organization.label" readonly/>
        </b-form-group>
        <b-form-group :label="$t('comp.projects.pi.label')">
          <b-form-input v-if="form.pi" v-model="form.pi.label" readonly/>
          <b-form-input v-else :value="this.$i18n.t('comp.projects.show.nopi')" readonly/>
        </b-form-group>
        <b-form-checkbox v-model="form.piMuted" name="piMuted" class="my-3" switch disabled>
          {{ $t('comp.projects.pimuted.label') }}
        </b-form-checkbox>
        <b-form-group :label="$t('comp.projects.advisors.label')">
          <b-form-tags  v-if="advisors" v-model="advisors" placeholder="" disabled/>
          <b-form-input v-else :value="this.$i18n.t('comp.projects.show.noadvisors')" readonly/>
        </b-form-group>
        <b-form-group :label="$t('comp.projects.managers.label')">
          <b-form-tags  v-if="managers" v-model="managers" placeholder="" disabled/>
          <b-form-input v-else :value="this.$i18n.t('comp.projects.show.nomanagers')" readonly/>
        </b-form-group>
        <b-form-group :label="$t('comp.projects.state.label')">
          <b-form-input v-model="form.state" readonly/>
        </b-form-group>
        <b-form-group :label="$t('comp.projects.prefix.label')">
          <b-form-input v-model="form.prefix" readonly/>
        </b-form-group>
        <b-form-group :label="$t('comp.projects.validFrom.label')" label-for="valid-from">
          <b-form-datepicker no-flip id="valid-from" :showDecadeNav="showDateTimeDecadeNav" v-model="form.validFrom"
                             :value-as-date="true" v-bind="datePickerLabels || {}" class="mb-2" disabled/>
        </b-form-group>
        <b-form-group :label="$t('comp.projects.validUntil.label')" label-for="valid-until">
          <b-form-datepicker no-flip id="valid-until" :showDecadeNav="showDateTimeDecadeNav" v-model="form.validUntil"
                             :value-as-date="true" v-bind="datePickerLabels || {}" class="mb-2" disabled/>
        </b-form-group>
        <b-form-group :label="$t('comp.projects.accessibleFrom.label')" label-for="accessible-from">
          <b-form-datepicker no-flip id="accessible-from" :showDecadeNav="showDateTimeDecadeNav" v-model="form.accessibleFrom"
                             :value-as-date="true" v-bind="datePickerLabels || {}" class="mb-2" disabled/>
        </b-form-group>
        <b-form-group :label="$t('comp.projects.accessibleUntil.label')" label-for="accessible-until">
          <b-form-datepicker no-flip id="accessible-until" :showDecadeNav="showDateTimeDecadeNav" v-model="form.accessibleUntil"
                             :value-as-date="true" v-bind="datePickerLabels || {}" class="mb-2" disabled/>
        </b-form-group>
        <b-form-group :label="$t('comp.projects.computeresources.label')">
          <b-form-tags v-model="computeResources" placeholder="" disabled/>
        </b-form-group>
        <b-form-group :label="$t('comp.projects.storageresources.label')">
          <b-form-tags v-model="storageResources" placeholder="" disabled/>
        </b-form-group>
      </b-card>
      <CommentCard :targetId="this.form.projectId.toString()" :showTable="viewEntry" view="advisor"/>
    </b-form>
    <div class="overflow-auto px-2 px-md-5" v-else>
      <div class="row justify-content-between justify-content-md-start mt-md-3">
        <div class="col-6 col-md-auto mb-3 pr-0">
          <b-button class="w-100" @click="showOnly('nhrprojects')" variant="primary">
            <b-icon-funnel-fill v-if="nhrProjectsFilterSet"/> <b-icon-funnel v-else/>
            {{ $t('comp.projects.showNhrOnly.label') }}
          </b-button>
        </div>
        <div class="col-6 col-md-auto mb-3 pr-md-0">
          <b-button class="w-100" @click="showOnly('fauprojects')" variant="primary">
            <b-icon-funnel-fill v-if="fauProjectsFilterSet"/> <b-icon-funnel v-else/>
            {{ $t('comp.projects.showFauOnly.label') }}
          </b-button>
        </div>
        <div class="col-6 col-md-auto mb-3 pr-0">
          <b-button class="w-100" @click="showOnly('aiprojects')" variant="primary">
            <b-icon-funnel-fill v-if="aiProjectsFilterSet"/> <b-icon-funnel v-else/>
            {{ $t('comp.projects.showAiOnly.label') }}
          </b-button>
        </div>
        <div class="col-6 col-md-auto mb-3 pr-md-0">
          <b-button class="w-100" @click="showOnly('nonaiprojects')" variant="primary">
            <b-icon-funnel-fill v-if="nonaiProjectsFilterSet"/> <b-icon-funnel v-else/>
            {{ $t('comp.projects.showNonAiOnly.label') }}
          </b-button>
        </div>
        <div class="col-12 col-md-auto mb-3 ml-md-auto align-self-center">
          <b-form-checkbox v-model="lessColumns" name="lessColumns" switch>
          {{ $t('comp.projects.lessColumns.label') }}
          </b-form-checkbox>
        </div>
      </div>
      <TableHeader @refresh="refresh" :filter.sync="filter" :per-page.sync="perPage" :current-page.sync="currentPage"
                   :rows="rows" :total-rows="totalRows" :searchables="searchables" table-id="projects-table"/>
      <b-table responsive id="projects-table" ref="projects-table" :busy.sync="isBusy" :fields="fields" :per-page="perPage"
               :current-page="currentPage" :filter="filter" :items="projectItemProvider" small striped hover>
        <template v-slot:cell(actions)="data">
          <div style="max-width: 100px">
            <b-button @click="showProject(data.item)" :title="$t('comp.projects.show.label')" variant="light"
                      size="sm" class="mr-1">
              <b-icon-search class="mr-1" shift-h="2" variant="primary"/>
            </b-button>
            <b-button v-b-modal="`usage-modal-${data.item.projectId}`" :title="$t('comp.projects.showusage.label')" variant="light"
                      size="sm" class="mr-1">
              <b-icon-bar-chart-line class="mr-1" shift-h="2" variant="info"/>
            </b-button>
            <b-button v-b-modal="`accounts-modal-${data.item.projectId}`" :title="$t('comp.projects.showaccounts.label')" variant="light"
                      size="sm" class="mt-1 mr-1">
              <b-icon-person-circle class="mr-1" shift-h="2" variant="primary"/>
            </b-button>
          </div>
          <b-modal :id="`usage-modal-${data.item.projectId}`" :title="$t('comp.projects.usage.display.label', { project: data.item.projectId })" size="xl">
            <div>
              <ProjectUsageInfo :project="data.item.projectId" view="advisor"/> <!-- type="project" -->
            </div>
          </b-modal>
          <b-modal :id="`accounts-modal-${data.item.projectId}`" :title="$t('comp.projects.projectaccounts.label', { project: data.item.projectId, title: data.item.title })" size="xl">
            <div>
              <ProjectAccounts :project="data.item" view="advisor"/>
            </div>
          </b-modal>
        </template>
        <template v-slot:cell(progress)="data">
          <UsageProgress :data="data.item.progress"/>
        </template>
        <template v-slot:cell(aiRelated)="data">
          <b-button :id="`airelated-popover${data.item.projectId}`" variant="light" size="sm"  style="border-color:darkgray">
            <b-icon-cpu-fill v-if="data.item.aiRelated" shift-v="-1" variant="success"/>
            <b-icon-cpu v-else shift-v="-1" variant="secondary"/>
          </b-button>
          <b-popover :target="`airelated-popover${data.item.projectId}`" triggers="hover" placement="right">
            <template #title>{{ $t('comp.projects.airelated.long.label') }}</template>
            {{  data?.item?.aiRelated ? $t('comp.projects.airelated.yes.label') : $t('comp.projects.airelated.no.label') }}
          </b-popover>
        </template>
        <template v-slot:cell(pi)="data">
          <b-icon v-if="data?.item?.piMuted" :title="$t('comp.projects.pimuted.title')" icon="bell-slash-fill" variant="danger" shift-v="+1" scale="0.90" class="mr-1 cursor-help"/>
          {{ data?.item?.pi?.label ? data.item.pi.label : "-"}}
        </template>
        <template v-slot:cell(managers)="data">
          <div v-if="!data?.item?.managers || data.item.managers.length == 0">
            -
          </div>
          <div v-else-if="data.item.managers.length == 1">
            {{ data.item.managers[0].label }}
          </div>
          <div v-else>
            <b-button :id="`managers-popover${data.item.projectId}`" variant="light" size="sm"  style="border-color:darkgray">
              <b-icon-people-fill shift-v="-1" variant="primary"/>
            </b-button>
            <b-popover :target="`managers-popover${data.item.projectId}`" triggers="hover" placement="right">
              <template #title>{{ $t('comp.projects.managers.label') }}</template>
              <ul>
                <li v-for="manager in data.item.managers" :key="manager.key">{{ manager.label }}</li>
              </ul>
            </b-popover>
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import { i18nMixin } from '@/mixins/i18n.mixin'
import { projectFilterMixin } from '@/mixins/tableFilter.mixin'
import datepickerMixin from '@/mixins/datepicker.mixin'
import { projectServiceForAdvisorView } from '@/services/project.service'
import TableHeader from '@/components/generic/helper/TableHeader'
import ViewButtons from '@/components/generic/helper/ViewButtons'
import CommentCard from '@/components/generic/helper/CommentCard'
import ProjectUsageInfo from '@/components/generic/info/ProjectUsageInfo'
import ProjectAccounts from '@/components/generic/helper/ProjectAccounts'
import UsageProgress from '@/components/generic/chart/UsageProgress'

export default {
  name: 'Projects',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin, datepickerMixin, projectFilterMixin],
  components: {
    TableHeader,
    ViewButtons,
    CommentCard,
    ProjectUsageInfo,
    ProjectAccounts,
    UsageProgress
  },
  props: {
    presetFilter: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isBusy: false,
      perPage: 10,
      currentPage: 1,
      rows: 0,
      totalRows: 0,
      filter: this.presetFilter,
      form: null,
      viewEntry: false,
      showDateTimeDecadeNav: true,
      lessColumns: true,
      advisors: [],
      managers: [],
      computeResources: [],
      storageResources: []
    }
  },
  watch: {
    filter (newFilter, oldFilter) {
      if (this.presetFilter) {
        // If preset exists, reset if filter changed
        this.$emit('resetPreset')
      }
    }
  },
  computed: {
    domainLabel () {
      if (this.viewEntry === true && this?.form?.domain) {
        if (this.$i18n.locale === 'en') {
          return this.form.domain.label.en
        } else if (this.$i18n.locale === 'de') {
          return this.form.domain.label.de
        } else { // Fallback
          return this.form.domain.label.de
        }
      } else {
        return null
      }
    },
    provinceLabel () {
      if (this.viewEntry === true) {
        return this.messagesAvailable ? this.$i18n.t(`provinces.${this.form.province}.label`) : this.form.province
      } else {
        return null
      }
    },
    fields () {
      const fields = [
        { key: 'actions', label: this.$i18n.t('actions.label'), sortable: false, searchable: false, thStyle: 'min-width:100px;' },
        { key: 'progress', sortable: false, searchable: false, thStyle: 'min-width:100px;' },
        { key: 'projectId' },
        { key: 'title' },
        { key: 'projectType', select: true },
        { key: 'domain', directLocalize: true, toggleable: true },
        { key: 'aiRelated', boolean: true, searchable: false },
        { key: 'province', localize: true, searchable: false, toggleable: true },
        { key: 'organization', select: true },
        { key: 'unixGroupName' },
        { key: 'state', localize: true, searchable: false },
        { key: 'accessibleFrom', date: true, searchable: false, toggleable: true },
        { key: 'accessibleUntil', date: true, searchable: false, toggleable: true },
        { key: 'validFrom', date: true, searchable: false },
        { key: 'validUntil', date: true, searchable: false },
        { key: 'pi' },
        { key: 'managers' },
        { key: 'computeResources', array: true, toggleable: true },
        { key: 'storageResources', array: true, toggleable: true }
      ]
      _.each(fields, (field) => {
        if (field.sortable == null) {
          field.sortable = true
        }
        if (field.searchable == null) {
          field.searchable = true
        }
        if (field.toggleable == null) {
          field.toggleable = false
        }
        if (field.toggleable) {
          field.thClass = `${this.lessColumns ? 'd-none' : ''}`
          field.tdClass = `${this.lessColumns ? 'd-none' : ''}`
        }
        if (field.label == null) {
          field.label = this.$i18n.t(`comp.projects.${field.key}.label`)
        }
        if (field.date) {
          field.formatter = (value, key, item) => {
            return value ? moment(value).format('YYYY-MM-DD HH:mm') : ''
          }
          field.sortByFormatted = true
        }
        if (field.select) {
          field.sortKey = field.key + '.label'
          field.formatter = (value, key, item) => {
            return _.has(value, 'label') ? value.label : ''
          }
        }
        if (field.array) {
          field.formatter = (value) => {
            if (value !== null) {
              let arrayLabel = ''
              value.forEach((elem, index) => {
                arrayLabel += ('[' + elem.label.replace('-', '‑') + ']') // make nonbreaking hyphen
                if (index !== value.length - 1) {
                  arrayLabel += ', '
                }
              })
              return arrayLabel
            }
          }
        }
        if (field.localize) {
          field.formatter = (value) => {
            return this.$i18n.t(`${field.key}s.${value}.label`)
          }
        }
        if (field.directLocalize) {
          field.formatter = (value) => {
            if (value?.label) {
              if (this.$i18n.locale === 'en') {
                return value.label.en
              } else if (this.$i18n.locale === 'de') {
                return value.label.de
              } else { // Fallback
                return value.label.de
              }
            } else {
              return '-'
            }
          }
        }
      })
      return fields
    },
    searchables () {
      const localized = []
      this.fields.forEach((field) => {
        if (field.searchable === true) localized.push(this.$i18n.t(`comp.projects.${field.key}.label`))
      })
      return localized
    }
  },
  created () {
    projectServiceForAdvisorView.count({ filter: this.filter }).then((response) => (this.rows = response))
    projectServiceForAdvisorView.count().then((response) => (this.totalRows = response))
  },
  methods: {
    refresh () {
      projectServiceForAdvisorView.count().then((response) => (this.totalRows = response))
      if (this.$refs['projects-table']) {
        this.$refs['projects-table'].refresh()
      }
    },
    showProject (item) {
      this.viewEntry = true
      this.form = _.cloneDeep(item)
      if (!_.isEmpty(item.advisors)) {
        item.advisors.forEach(element =>
          this.advisors.push(element.label)
        )
      } else {
        this.advisors = null
      }
      if (!_.isEmpty(item.managers)) {
        item.managers.forEach(element =>
          this.managers.push(element.label)
        )
      } else {
        this.managers = null
      }
      if (!_.isEmpty(item.computeResources)) {
        item.computeResources.forEach(element =>
          this.computeResources.push(element.label)
        )
      } else {
        this.computeResources = null
      }
      if (!_.isEmpty(item.storageResources)) {
        item.storageResources.forEach(element =>
          this.storageResources.push(element.label)
        )
      } else {
        this.storageResources = null
      }
    },
    goBack () {
      this.viewEntry = false
      this.form = null
      this.advisors = []
      this.managers = []
      this.computeResources = []
      this.storageResources = []
    },
    projectItemProvider (ctx) {
      return projectServiceForAdvisorView.list(ctx).then((data) => {
        this.rows = data.count
        return data.items
      }).catch(error => {
        console.log(error)
        return []
      })
    }
  }
}
</script>
